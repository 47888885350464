import ChatComponent, { chatFullWidth, chatSideGap, chatWidth } from "./ChatComponent"
import ExhumanComponent, { defaultExhumanWidth, exhumanInVoiceModeWidthSmall } from "./ExhumanComponent"
import './css/ChatWithExhumanComponent.css'
import ProfileWidget from "./ProfileWidget"
import HeaderComponent, { headerHeight } from "./HeaderComponent"
import { useDispatch, useSelector } from "react-redux"
import { selectCurrentAvatarConfig, selectIsChatShownInVoiceMode, selectIsVoiceMode, setIsVoiceMode } from "../../app/redux/defaultSlice"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import CallButtonsStack from "./CallButtonsComponent"
import { StartCallButton } from "../Supporting/ButtonsComponents"

const gap = 25

export default () => {
    const currentAvatar = useSelector(selectCurrentAvatarConfig)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        if (!currentAvatar) {
            navigate('/')
        }
    }, [currentAvatar])

    const isVoiceMode = useSelector(selectIsVoiceMode)
    const isChatShownInVoiceMode = useSelector(selectIsChatShownInVoiceMode)

    let exhWidth
    let isMinimalMargin = false
    let contentWidth = undefined
    if (isVoiceMode) {
        if (isChatShownInVoiceMode) {
            exhWidth = exhumanInVoiceModeWidthSmall
            contentWidth = exhumanInVoiceModeWidthSmall + gap + chatFullWidth

        } else {
            exhWidth = '100%'
            isMinimalMargin = true
        }

    } else {
        contentWidth = defaultExhumanWidth + gap + chatFullWidth
        exhWidth = defaultExhumanWidth
    }

    return <div className='h-full' style={{ background: isVoiceMode ? 'linear-gradient(180deg, #fff 0%, #0094FF40 100%)' : 'linear-gradient(180deg, #F9FAFB 0%, #E3E7EC 100%)' }}>

        <HeaderComponent />

        <div
            className='main-block'
            style={{
                display: 'flex',
                width: contentWidth,
                minWidth: 500,
                marginLeft: isMinimalMargin ? 0 : `max(50% - ${contentWidth! / 2}px, 25px)`,
                height: `calc(100% - ${headerHeight}px)`,
            }}>
                
            <div
                style={{
                    height: '100%',
                    paddingTop: 25,
                    marginRight: isVoiceMode ? 0 : gap,
                    width: exhWidth,
                }}>
                <ExhumanComponent />
            </div>

            <div
                style={{
                    width: chatFullWidth,
                    height: '100%',
                    marginBottom: 25,
                    display: (isVoiceMode && !isChatShownInVoiceMode) ? 'none' : undefined
                }}>
                <ChatComponent />
            </div>

            <div className='flex justify-center absolute bottom-0 left-0 right-0'>
                {isVoiceMode &&
                    <CallButtonsStack/>}
            </div>
        </div>
    </div>
}
